/* Variables */
.categories,
.article-item,
.layout,
.box {
  /*outline-color: blue;
    outline-style: solid;
    outline-width: 1px;*/
}
.article-item .image > div {
  background-color: #DDD;
}
.hs-tools-menu {
  /*display: none !important;*/
}
/* Mixins */
/* Includes */
/* ### Box model */
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
html,
body {
  height: 100%;
}
/* ### Body definition */
body {
  position: relative;
  margin: 0;
  padding: 0;
  text-align: left;
  /* Fallback for IE8 */
}
.br-ie8 body {
  min-width: 1260px;
}
/* ### Image reset */
img {
  padding: 0;
  margin: 0;
}
/* ### Form reset */
form {
  margin: 0;
  padding: 0;
}
/* ### Horizontal line definition */
hr {
  color: transparent;
  height: 1px;
  border: none;
  text-align: center;
  border-top: 1px solid #e6ebf2;
  margin: 0;
}
/* Table reset */
table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}
table td {
  margin: 0;
  padding: 0;
}
a img {
  border: 0;
}
/* ### Light - font-weight: 300 */
@font-face {
  font-family: 'Proxima Nova';
  src: url('../../../o/f/300/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'), url('../../../o/f/300/proximanova-light-webfont.woff2') format('woff2'), url('../../../o/f/300/proximanova-light-webfont.woff') format('woff'), url('../../../o/f/300/proximanova-light-webfont.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}
/* ### Light - font-weight: 300, font-style: italic */
@font-face {
  font-family: 'Proxima Nova';
  src: url('../../../o/f/300/proximanova-lightit-webfont.eot?#iefix') format('embedded-opentype'), url('../../../o/f/300/proximanova-lightit-webfont.woff2') format('woff2'), url('../../../o/f/300/proximanova-lightit-webfont.woff') format('woff'), url('../../../o/f/300/proximanova-lightit-webfont.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
}
/* ### Regular - font-weight: 400 */
@font-face {
  font-family: 'Proxima Nova';
  src: url('../../../o/f/400/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../../../o/f/400/proximanova-regular-webfont.woff2') format('woff2'), url('../../../o/f/400/proximanova-regular-webfont.woff') format('woff'), url('../../../o/f/400/proximanova-regular-webfont.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}
/* ### Bold - font-weight: 700 */
@font-face {
  font-family: 'Proxima Nova';
  src: url('../../../o/f/700/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../../../o/f/700/proximanova-bold-webfont.woff2') format('woff2'), url('../../../o/f/700/proximanova-bold-webfont.woff') format('woff'), url('../../../o/f/700/proximanova-bold-webfont.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
body {
  font-family: 'Proxima Nova', 'Helvetica Neue', 'Roboto', Helvetica, Arial, sans-serif;
  color: #6c7c92;
}
h1 {
  color: #FFF;
  font-size: 44px;
  line-height: 1.1;
  margin: 30px 0;
}
@media only screen and (max-width: 979px) {
  h1 {
    font-size: 32px;
  }
}
h2,
h3,
h4 {
  color: #31323d;
}
h2 {
  font-size: 40px;
  margin: 30px 0;
}
h3 {
  font-size: 18px;
  margin: 20px 0;
}
.x-listing .layout-listing-right h2 {
  font-size: 28px;
}
.x-listing .layout-listing-right .article-items-outer + h2 {
  margin-top: 50px;
}
/* ### Show, hide helpers */
/* Default definition */
.show-desktop,
.show-tablet,
.show-mobile {
  display: block !important;
}
.hide-desktop,
.hide-tablet,
.hide-mobile {
  display: none !important;
}
/* Desktop definition */
@media only screen and (min-width: 1025px) {
  .show-desktop,
  .hide-tablet,
  .hide-mobile {
    display: block !important;
  }
  .hide-desktop,
  .show-desktop,
  .show-tablet,
  .show-mobile {
    display: none !important;
  }
}
/* Tablet definition */
@media only screen and (min-width: 980px) and (max-width: 1024px) {
  .show-tablet,
  .hide-desktop,
  .hide-mobile {
    display: block !important;
  }
  .hide-tablet,
  .show-desktop,
  .show-mobile {
    display: none !important;
  }
}
/* Mobile definition */
@media only screen and (max-width: 979px) {
  .show-mobile,
  .hide-desktop,
  .hide-tablet {
    display: block !important;
  }
  .hide-mobile,
  .show-desktop,
  .show-tablet {
    display: none !important;
  }
}
/* ### Clearfix */
.clearfix {
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.clearfix:after,
.clearfix:before {
  display: table;
  content: " ";
}
.AVsizer {
  padding-left: 20px !important;
  padding-right: 20px !important;
  max-width: 1260px;
  margin: auto;
}
.br-ie8 .AVsizer {
  width: 1260px;
}
@media only screen and (min-width: 1025px) {
  .AVsizer {
    width: 1260px;
  }
}
.row {
  *zoom: 1;
  margin-left: -20px;
  margin-right: -20px;
}
.row:before,
.row:after {
  display: table;
  line-height: 0;
  content: "";
}
.row:after {
  clear: both;
}
.row [class*="span"] {
  float: left;
  min-height: 1px;
  padding: 20px;
}
.row-main {
  margin-left: 0;
  margin-right: 0;
}
/* switching left and right column */
.row.switch [class*="span"] {
  float: right;
}
/*  nopadding row */
.row.nopadding [class*="span"] {
  padding: 0px;
}
/* rowspan definitions */
.span12 {
  width: 100%;
}
.span10 {
  width: 83.33333332%;
}
.span9 {
  width: 75%;
}
.span8 {
  width: 66.66666665%;
}
.span7 {
  width: 58.33333332%;
}
.span6 {
  width: 50%;
}
.span5 {
  width: 41.66666665%;
}
.span4 {
  width: 33.33333332%;
}
.span3 {
  width: 25%;
}
.span2 {
  width: 16.66666667%;
}
.span-offset3 {
  margin-left: 25%;
}
.span-offset4 {
  margin-left: 33.33333332%;
}
.span-offset5 {
  margin-left: 41.66666665%;
}
.span-offset6 {
  margin-left: 50%;
}
.span-offset7 {
  margin-left: 58.33333332%;
}
.span-offset8 {
  margin-left: 66.66666665%;
}
.span-offset9 {
  margin-left: 75%;
}
/* #### Tablet and mobile resolution overwrite */
@media only screen and (min-width: 980px) and (max-width: 1024px), only screen and (max-width: 979px) {
  /* fixing size of the images inside grid */
  [class*="span"] > img {
    max-width: 100%;
  }
}
@media only screen and (max-width: 979px) {
  .row {
    *zoom: 1;
    width: auto;
    display: block;
  }
  .row [class*="span"] {
    float: none;
    min-height: 1px;
    padding: 20px;
    width: auto;
    display: block;
  }
  .row [class*="span-offset"] {
    margin-left: 0;
  }
  .row.switch [class*="span"] {
    float: none;
  }
  .row.nopadding [class*="span"] {
    padding: 0 0 20px;
  }
}
body {
  background-color: #f6f9fc;
}
body.x-detail,
body.x-author-detail {
  background-color: #FFF;
}
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  body {
    width: 1260px;
  }
}
@media only screen and (min-width: 980px) and (max-width: 1024px) {
  body {
    width: 100%;
  }
}
.body-inner {
  background: url('../../../i/mkt/blog/bg-top-purple-gray.png') no-repeat 50% -245px;
}
@media only screen and (min-width: 1921px) {
  .body-inner {
    background-size: contain !important;
    background: url('../../../i/mkt/blog/bg-top-large-purple-gray.png') no-repeat 50% 0;
  }
}
@media only screen and (max-width: 979px) {
  .body-inner {
    background: url('../../../i/mkt/blog/bg-top-mobile-purple.png') no-repeat 50% 0;
    background-size: contain;
  }
}
.x-detail .body-inner,
.x-author-detail .body-inner {
  background: url('../../../i/mkt/blog/bg-top-large-blue_new.png') no-repeat 50% 0;
}
@media only screen and (min-width: 1921px) {
  .x-detail .body-inner,
  .x-author-detail .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-blue_new.png');
  }
}
.x-security-news .body-inner {
  background: url('../../../i/mkt/blog/bg-top-gray-gray.png') no-repeat 50% 0;
}
@media only screen and (min-width: 1921px) {
  .x-security-news .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-gray-gray.png');
  }
}
.x-mobile-security .body-inner {
  background: url('../../../i/mkt/blog/bg-top-blue-gray.png') no-repeat 50% 0;
}
@media only screen and (min-width: 1921px) {
  .x-mobile-security .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-blue-gray.png');
  }
}
.x-avast-news .body-inner,
.x-authors .body-inner {
  background: url('../../../i/mkt/blog/bg-top-orange-gray.png') no-repeat 50% 0;
}
@media only screen and (min-width: 1921px) {
  .x-avast-news .body-inner,
  .x-authors .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-orange-gray.png');
  }
}
.x-tips-advice .body-inner {
  background: url('../../../i/mkt/blog/bg-top-green-gray.png') no-repeat 50% 0;
}
@media only screen and (min-width: 1921px) {
  .x-tips-advice .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-green-gray.png');
  }
}
.x-threat-research .body-inner {
  background: url('../../../i/mkt/blog/bg-top-yellow-gray.png') no-repeat 50% 0;
}
@media only screen and (min-width: 1921px) {
  .x-threat-research .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-yellow-gray.png');
  }
}
.x-business-security .body-inner {
  background: url('../../../i/mkt/blog/bg-top-plum-gray.png') no-repeat 50% 0;
}
@media only screen and (min-width: 1921px) {
  .x-business-security .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-plum-gray.png');
  }
}
.x-garry-kasparov .body-inner {
  background: url('../../../i/mkt/blog/bg-top-purple-gray.png') no-repeat 50% 0;
}
@media only screen and (min-width: 1921px) {
  .x-garry-kasparov .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-purple-gray.png');
  }
}
@media only screen and (max-width: 979px) {
  .x-security-news .body-inner {
    background: url('../../../i/mkt/blog/bg-top-mobile-gray.png') no-repeat 50% 0;
    background-size: initial;
  }
  .x-mobile-security .body-inner {
    background: url('../../../i/mkt/blog/bg-top-mobile-blue.png') no-repeat 50% 0;
    background-size: initial;
  }
  .x-avast-news .body-inner {
    background: url('../../../i/mkt/blog/bg-top-mobile-orange.png') no-repeat 50% 0;
    background-size: initial;
  }
  .x-tips-advice .body-inner {
    background: url('../../../i/mkt/blog/bg-top-mobile-green.png') no-repeat 50% 0;
    background-size: initial;
  }
  .x-threat-research .body-inner {
    background: url('../../../i/mkt/blog/bg-top-mobile-yellow.png') no-repeat 50% 0;
    background-size: initial;
  }
  .x-business-security .body-inner {
    background: url('../../../i/mkt/blog/bg-top-mobile-plum.png') no-repeat 50% 0;
    background-size: initial;
  }
  .x-garry-kasparov .body-inner {
    background: url('../../../i/mkt/blog/bg-top-mobile-purple.png') no-repeat 50% 0;
    background-size: initial;
  }
}
.x-detail.x-security-news .body-inner {
  background: url('../../../i/mkt/blog/bg-top-gray.png') no-repeat 50% 0;
}
/* @media only screen and (min-width: 1921px) {
  .x-detail.x-security-news .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-detial-gray.png');
  }
} */
.x-detail.x-mobile-security .body-inner {
  background: url('../../../i/mkt/blog/bg-top-blue.png') no-repeat 50% 0;
}
/* @media only screen and (min-width: 1921px) {
  .x-detail.x-mobile-security .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-detial-gray.png');
  }
} */
.x-detail.x-avast-news .body-inner {
  background: url('../../../i/mkt/blog/bg-top-orange.png') no-repeat 50% 0;
}
/* @media only screen and (min-width: 1921px) {
  .x-detail.x-avast-news .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-detial-orange.png');
  }
} */
.x-detail.x-tips-advice .body-inner {
  background: url('../../../i/mkt/blog/bg-top-green.png') no-repeat 50% 0;
}
/* @media only screen and (min-width: 1921px) {
  .x-detail.x-tips-advice .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-detial-green.png');
  }
} */
.x-detail.x-threat-research .body-inner {
  background: url('../../../i/mkt/blog/bg-top-yellow.png') no-repeat 50% 0;
}
/* @media only screen and (min-width: 1921px) {
  .x-detail.x-threat-research .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-detial-yellow.png');
  }
} */
.x-detail.x-business-security .body-inner {
  background: url('../../../i/mkt/blog/bg-top-plum.png') no-repeat 50% 0;
}
/* @media only screen and (min-width: 1921px) {
  .x-detail.x-business-security .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-detial-plum.png');
  }
} */
.x-detail.x-garry-kasparov .body-inner {
  background: url('../../../i/mkt/blog/bg-top-purple.png') no-repeat 50% 0;
}
/* @media only screen and (min-width: 1921px) {
  .x-detail.x-garry-kasparov .body-inner {
    background-image: url('../../../i/mkt/blog/bg-top-large-detial-purple.png');
  }
} */
@media only screen and (min-width: 1025px), only screen and (min-width: 980px) and (max-width: 1024px) {
  .x-listing .body-inner,
  .x-author-detail .body-inner {
    background-position: 50% -245px;
  }
}
@media only screen and (min-width: 1025px) {
  .layout-listing-left {
    width: 76.2295%;
    /* 930px */
  }
  .layout-listing-right {
    width: 23.7705%;
    /* 290px */
    margin-top: 145px;
  }
}
@media only screen and (min-width: 980px) and (max-width: 1024px) {
  .row .layout-listing-left,
  .row .layout-listing-right {
    float: none;
  }
}
@media only screen and (max-width: 979px) {
  .row .layout-listing-right {
    padding: 0;
  }
}
.layout-detail {
  width: 100%;
  float: none;
  margin-bottom: 100px;
}
@media only screen and (min-width: 1025px), only screen and (min-width: 980px) and (max-width: 1024px) {
  .layout-detail {
    width: 900px;
    margin: auto auto 100px;
  }
}
a[class*="tag-"]:hover {
  color: #8050ff;
}
.tag {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  display: inline-block;
}
.x-tips-advice .tag-tips-advice,
.tag-tips-advice,
a.tag-tips-advice:hover {
  color: #0cb754;
}
.x-avast-news .tag-avast-news,
.tag-avast-news,
a.tag-avast-news:hover {
  color: #fd7822;
}
.x-mobile-security .tag-mobile-security,
.tag-mobile-security,
a.tag-mobile-security:hover {
  color: #5b93fc;
}
.x-threat-research .tag-threat-research,
.tag-threat-research,
a.tag-threat-research:hover {
  color: #f0c730;
}
.x-security-news .tag-security-news,
.tag-security-news,
a.tag-security-news:hover {
  color: #aaabb6;
}
.x-business-security .tag-business-security,
.tag-business-security,
a.tag-business-security:hover {
  color: #4659e6;
}
.x-garry-kasparov .tag-garry-kasparov,
.tag-garry-kasparov,
a.tag-garry-kasparov:hover {
  color: #8050ff;
}
.x-diversity-and-inclusion .tag-diversity-and-inclusion,
.tag-diversity-and-inclusion,
a.tag-diversity-and-inclusion:hover {
    color: #4E22D0;
}
.tag-detail {
  color: #FFF !important;
  font-size: 12px;
  line-height: 1;
  padding: 10px 12px 9px;
  min-width: 120px;
  position: relative;
  /*margin-left: 30px;*/
}
.tag-detail.tag {
  /*&:after {
                content: " ";
                display: block;
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-right: 5px solid #8050ff;
                position: absolute;
                left: -27px;
                top: 9px;
            }

            &:before {
                content: " ";
                display: block;
                width: 13px;
                height: 2px;
                position: absolute;
                left: -22px;
                top: 13px;
                background-color: #8050ff;
            }

            &-tips-advice:before {
                background-color: #0cb754;
            }

            &-avast-news:before {
                background-color: #fd7822;
            }

            &-mobile-security:before {
                background-color: #5b93fc;
            }

            &-threat-research:before {
                background-color: #f0c730;
            }

            &-security-news:before {
                background-color: #aaabb6;
            }

            &-business-security:before {
                background-color: #4659e6;
            }

            &-garry-kasparov:before {
                background-color: #8050ff;
            }

            &-tips-advice:after {
                border-right-color: #0cb754;
            }

            &-avast-news:after {
                border-right-color: #fd7822;
            }

            &-mobile-security:after {
                border-right-color: #5b93fc;
            }

            &-threat-research:after {
                border-right-color: #f0c730;
            }

            &-security-news:after {
                border-right-color: #aaabb6;
            }

            &-business-security:after {
                border-right-color: #4659e6;
            }

            &-garry-kasparov:after {
                border-right-color: #8050ff;
            }*/
}
.tag-detail.tag-tips-advice {
  background-color: #0cb754;
}
.tag-detail.tag-avast-news {
  background-color: #fd7822;
}
.tag-detail.tag-mobile-security {
  background-color: #5b93fc;
}
.tag-detail.tag-threat-research {
  background-color: #f0c730;
}
.tag-detail.tag-security-news {
  background-color: #aaabb6;
}
.tag-detail.tag-business-security {
  background-color: #4659e6;
}
.tag-detail.tag-garry-kasparov {
  background-color: #8050ff;
}
.tags {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.tags li {
  font-weight: bold;
  display: inline-block;
  color: #6c7c92;
  padding: 0 8px 6px 0;
  font-size: 15px;
}
.category {
  margin: 0 20px 20px 0;
  width: calc(33.3333332% - 20px);
}
.category a {
  color: #7c87a2;
  font-size: 14px;
  text-decoration: none;
  font-weight: bold;
  padding-left: 30px;
  position: relative;
}
.category a:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #8050ff;
  position: absolute;
  left: 0;
  top: 2px;
}
.category a:before {
  content: " ";
  display: block;
  width: 13px;
  height: 2px;
  position: absolute;
  left: 5px;
  top: 6px;
  background-color: #8050ff;
}
.category h2 {
  font-size: 46px;
  color: #FFF;
  line-height: 50px;
  position: relative;
  word-wrap: break-word;
}
.category h2:before {
  content: " ";
  position: absolute;
  bottom: -30px;
  left: 0;
  height: 4px;
  width: 50px;
  background: #8050ff;
}
@media only screen and (max-width: 979px) {
  .category {
    text-align: center;
  }
  .category h2:before {
    left: calc(50% - 25px);
  }
  .category h2 {
    color: #FFF !important;
  }
}
.x-tips-advice .category h2:before,
.x-tips-advice .category a:before {
  background-color: #0cb754;
}
.x-avast-news .category h2:before,
.x-avast-news .category a:before {
  background-color: #fd7822;
}
.x-mobile-security .category h2:before,
.x-mobile-security .category a:before {
  background-color: #5b93fc;
}
.x-threat-research .category h2:before,
.x-threat-research .category a:before {
  background-color: #f0c730;
}
.x-security-news .category h2:before,
.x-security-news .category a:before {
  background-color: #aaabb6;
}
.x-business-security .category h2:before,
.x-business-security .category a:before {
  background-color: #4659e6;
}
.x-tips-advice .category a:after {
  border-right-color: #0cb754;
}
.x-avast-news .category a:after {
  border-right-color: #fd7822;
}
.x-mobile-security .category a:after {
  border-right-color: #5b93fc;
}
.x-threat-research .category a:after {
  border-right-color: #f0c730;
}
.x-security-news .category a:after {
  border-right-color: #aaabb6;
}
.x-business-security .category a:after {
  border-right-color: #4659e6;
}
.categories {
  margin: 0 20px 20px 0;
  width: calc(33.3333332% - 20px);
  background-color: #FFF;
  -webkit-box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.3);
  /*@media @tablet {
        margin: 0 0 20px;
        width: auto;
    }*/
}
:root .categories {
  padding: 30px;
}
.x-tips-advice .categories h2:before {
  background-color: #0cb754;
}
.x-avast-news .categories h2:before {
  background-color: #fd7822;
}
.x-mobile-security .categories h2:before {
  background-color: #5b93fc;
}
.x-threat-research .categories h2:before {
  background-color: #f0c730;
}
.x-security-news .categories h2:before {
  background-color: #aaabb6;
}
.x-business-security .categories h2:before {
  background-color: #4659e6;
}
.categories h2 {
  font-size: 26px;
  line-height: 1.30;
  margin: 10px 0 45px;
  padding: 0 0 25px;
  border-bottom: 1px solid #e6ebf2;
  position: relative;
}
.categories h2:before {
  content: " ";
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 2px;
  width: 100%;
  background: #8050ff;
}
.categories ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.categories ul li {
  margin: 0 0 20px;
}
.categories ul li a {
  font-size: 16px;
  font-weight: bold;
  color: #6c7c92;
  text-decoration: none;
}
.categories ul li a .count {
  color: #bec7d4;
  font-size: 13px;
  display: inline-block;
  float: right;
  padding: 4px 0 0;
}
@media only screen and (max-width: 979px) {
  :root .categories {
    display: none;
  }
}
@media only screen and (min-width: 1025px), only screen and (min-width: 980px) and (max-width: 1024px) {
  .article-items {
    display: flex;
  }
  .article-items-std,
  .article-items-parallax {
    display: block;
  }
}
.article-item a {
  text-decoration: none;
  color: initial;
}
.row .article-item {
  padding: 0;
}
.article-item .image > div {
  background-size: cover;
  padding-bottom: 56.25%;
  height: 0;
  background-repeat: no-repeat;
}
.article-item-medium,
.article-item-large {
  margin: 0 20px 20px 0;
}
.article-item-medium .image > div,
.article-item-large .image > div {
  position: relative;
  z-index: 10;
  width: 100%;
}
.article-item-medium .box,
.article-item-large .box {
  width: calc(100% - 40px);
  margin: -82px 20px 0;
  position: relative;
  z-index: 20;
  padding: 33px;
}
.article-item-medium .box:before,
.article-item-large .box:before {
  display: block;
  content: " ";
  width: 100%;
  height: 175px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  background-color: #f6f9fc;
}
.article-item-medium .box *,
.article-item-large .box * {
  position: relative;
  z-index: 40;
}
.article-item-medium .meta-added,
.article-item-large .meta-added {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #e6ebf2;
}
.article-item-small .meta,
.article-item-medium .meta,
.article-item-large .meta {
  color: #94a0b1;
  font-size: 13px;
}
.article-item-small .meta-added,
.article-item-medium .meta-added,
.article-item-large .meta-added {
  font-weight: bold;
  display: inline-block;
}
.article-item-large {
  width: calc(66.6666665% - 20px);
  position: relative;
}
.article-item-large .image {
  width: 100%;
  position: relative;
}
.article-item-large .image:before {
  content: " ";
  display: block;
  z-index: 5;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  height: 10px;
  margin: 0 5%;
  -webkit-box-shadow: 0px 16px 52px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 16px 52px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 16px 52px 6px rgba(0, 0, 0, 0.2);
}
.article-item-large .tag {
  margin: 0 0 10px;
}
.article-item-large h3,
.article-item-large h1 {
  font-size: 28px;
  line-height: 1.1;
  margin: 0 0 15px;
}
.article-item-large h1 {
  color: initial;
}
.article-item-large p {
  color: #6c7c92;
  font-size: 18px;
  line-height: 1.33;
  margin: 20px 0;
}
.article-item-medium {
  width: calc(33.3333332% - 20px);
  margin-bottom: 50px;
  position: relative;
}
.article-item-medium .image {
  width: 100%;
}
.article-item-medium .box {
  margin: -34px 20px 0;
  padding: 22px;
}
.article-item-medium .box:before {
  height: 90px;
}
.article-item-medium .tag {
  font-size: 11px;
  margin: 0 0 13px;
}
.article-item-medium h3 {
  font-size: 18px;
  line-height: 1.2;
  margin: 0 0 18px;
}
.article-item-medium p {
  color: #6c7c92;
  font-size: 16px;
  line-height: 1.33;
  margin: 20px 0 45px;
}
.article-item-medium .meta {
  position: absolute;
  bottom: 20px;
  left: 40px;
}
.article-item-medium .meta-read {
  font-weight: bold;
}
@media only screen and (max-width: 979px) {
  .article-item-medium .meta {
    position: absolute;
    left: 0;
    bottom: 20px;
  }
}
.article-item-medium:after {
  content: " ";
  display: block;
  width: calc(100% - 40px);
  height: 1px;
  border-bottom: 1px solid #e6ebf2;
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0 20px;
}
.article-item-small .tag {
  font-size: 11px;
  margin: 0 0 13px;
}
.article-item-small h3 {
  font-size: 15px;
  line-height: 1.2;
  margin: 0;
}
.article-item-small .meta-added {
  margin: 12px 0 0;
}
.article-item-small:after {
  clear: both;
  content: " ";
  width: 100%;
  display: table;
  overflow: hidden;
}
.article-item-ad:after {
  display: none;
}
:root .article-item-small {
  border-bottom: 1px solid #e6ebf2;
  padding: 0 0 30px;
  margin: 0 0 30px;
}
@media only screen and (min-width: 980px) and (max-width: 1024px) {
  :root .article-item-small {
    width: 276px;
    min-width: 276px;
    margin: 0 40px 20px 0;
    position: relative;
    float: left;
  }
}
@media only screen and (max-width: 979px) {
  .article-item {
    margin: 0 0 20px;
  }
  .article-item-medium,
  .article-item-large {
    width: auto;
  }
}
@media only screen and (max-width: 979px) {
  .article-item-large,
  .article-item-medium {
    width: calc(33.3333332% - 20px);
    margin-bottom: 50px;
    position: relative;
  }
  .article-item-large .image:before,
  .article-item-medium .image:before {
    display: none;
  }
  .article-item-large .box,
  .article-item-medium .box {
    margin: 0;
    padding: 22px 0;
    width: 100%;
  }
  .article-item-large .box:before,
  .article-item-medium .box:before {
    height: 90px;
  }
  .article-item-large .tag,
  .article-item-medium .tag {
    font-size: 11px;
    margin: 0 0 13px;
  }
  .article-item-large h3,
  .article-item-medium h3 {
    font-size: 18px;
    line-height: 1.33;
    margin: 0 0 18px;
  }
  .article-item-large p,
  .article-item-medium p {
    color: #6c7c92;
    font-size: 16px;
    line-height: 1.50;
    margin: 20px 0;
  }
  .article-item-large .meta-read,
  .article-item-medium .meta-read {
    font-weight: bold;
  }
  .article-item-large:after,
  .article-item-medium:after {
    content: " ";
    display: block;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #e6ebf2;
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 980px) and (max-width: 1024px) {
  .layout-listing-right .article-items {
    width: 100%;
  }
  .layout-listing-right .article-items-outer {
    margin: 0 -40px;
    padding: 0 40px;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1025px) {
  .layout-listing-right .article-items {
    display: block;
  }
}
.nav {
  background: #20283a;
  width: 340px;
  position: fixed;
  overflow-y: scroll;
  right: -340px;
  top: 0;
  height: 100vh;
  z-index: 500;
  transition: right 300ms ease;
}
body.nav-opened {
  overflow: hidden;
}
.nav.active-navigation {
  right: 0px;
}
.nav-inner {
  padding: 150px 50px;
  overflow-y: auto;
  overflow-x: hidden;
}
.nav-title {
  color: #FFF;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  display: inline-block;
}
.nav-controls-close {
  position: absolute;
  top: 65px;
  right: 50px;
  color: #FFF;
  cursor: pointer;
  padding-right: 40px;
  background: url('../../../i/mkt/blog/icon-close.png') no-repeat right center;
}
.nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.nav ul li {
  clear: both;
  margin-bottom: 30px;
  position: relative;
}
.nav ul li.category-last-item {
  border-bottom: 1px solid #283146;
  padding-bottom: 30px;
}
.nav ul li.active:before {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: block;
  content: " ";
  background: #FFF;
  position: absolute;
  top: -1px;
  left: -61px;
  background: #7c87a2;
}
.nav ul a {
  display: block;
}
.nav ul a .count {
  float: right;
}
.nav ul a:after {
  clear: both;
  content: " ";
}
.nav a {
  color: #7c87a2;
  font-weight: bold;
  text-decoration: none;
}
.nav a.font-normal {
  font-weight: 400;
}
.navigation {
  position: absolute;
  top: 50px;
  right: 50px;
  color: #fff;
}
@media only screen and (max-width: 979px) {
  .navigation {
    top: 35px;
    right: 20px;
  }
}
.navigation-search {
  display: none;
  cursor: pointer;
  padding: 0 40px 0 20px;
  padding: 10px;
  width: 33px;
  height: 33px;
  background: url('../../../i/mkt/blog/icon-search-mobile.png') no-repeat left center;
  background-size: 16px;
}
.navigation-search span {
  display: none;
}
@media only screen and (max-width: 979px) {
  .navigation-search {
    display: inline-block;
  }
}
.navigation-home {
  cursor: pointer;
  display: inline-block;
  padding: 0 20px 0 30px;
  margin: 0 20px 0 0;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  background: url('../../../i/mkt/blog/amoeba_small.png') no-repeat left center;
  color: #FFF;
  text-decoration: none;
}
@media only screen and (max-width: 1679px) {
  .navigation-home {
    display: none;
  }
}
.navigation-switch {
  cursor: pointer;
  display: inline-block;
  padding: 0 40px 0 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  background: url('../../../i/mkt/blog/icon-hamburger-close.png') no-repeat right center;
}
@media only screen and (max-width: 979px) {
  .navigation-switch {
    background: url('../../../i/mkt/blog/icon-hamburger-mobile.png') no-repeat right center;
    padding: 10px;
    background-size: 20px;
    width: 33px;
    height: 33px;
  }
  .navigation-switch span {
    display: none;
  }
}
@media only screen and (max-width: 1679px) {
  .navigation-home {
    display: inline-block;
  }
}
@media only screen and (max-width: 979px) {
  .navigation-home {
    position: relative;
    top: -11px;
  }
}
@media only screen and (max-width: 540px) {
  .navigation-home {
    margin: 0 4px 0 0;
    font-size: 14px;
    background-image: none;
  }
}
@media only screen and (max-width: 500px) {
  .navigation-home {
    font-size: 11px;
  }
}
@media only screen and (max-width: 430px) {
  .navigation-home {
    font-size: 10px;
  }
}
@media only screen and (max-width: 359px) {
  .navigation-home {
    display: none;
  }
}
.search {
  display: none !important;
}
@media only screen and (max-width: 430px) {
  .logo img {
    width: 150px;
    height: auto;
  }
}
@media only screen and (max-width: 979px) {
  #content-holder {
    filter: blur(0px);
    transition: filter 200ms ease;
  }
  .search-opened #content-holder {
    filter: blur(2px);
  }
}
.header {
  height: 126px;
}
@media only screen and (min-width: 1921px) {
  .header {
    width: 1920px;
    margin: auto;
    position: relative;
  }
}
@media only screen and (max-width: 979px) {
  .header {
    height: 90px;
  }
}
.header .AVsizer:after {
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.header .AVsizer:after:after,
.header .AVsizer:after:before {
  display: table;
  content: " ";
}
.header .logo {
  text-decoration: none;
}
.header .logo img {
  display: inline-block;
}
.header .logo h1 {
  display: inline-block;
  margin: 0px;
  margin-left: 30px;
  padding-left: 25px;
  font-size: 18px;
  border-left: 1px solid #7c87a2;
  color: #7c87a2;
  font-weight: normal;
  position: relative;
  top: -13px;
}
.header .search {
  margin-left: 280px;
  width: 34%;
  position: relative;
  top: 40px;
}
@media only screen and (min-width: 1025px) {
  .header .search {
    margin-left: 310px;
    width: 600px;
  }
}
.header .search .search-controls-close {
  display: none;
}
@media only screen and (max-width: 979px) {
  .header .search {
    transition: top 300ms ease;
    position: absolute;
    left: 0;
    width: 100%;
    height: 30vh;
    background: #20283a;
    margin: 0;
    z-index: 100;
    padding-top: 100px;
    top: -30vh;
  }
  .header .search-active {
    top: 0;
  }
  .header .search .search-controls-close {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    color: #FFF;
    cursor: pointer;
    padding-right: 40px;
    background: url('../../../i/mkt/blog/icon-close.png') no-repeat right center;
  }
}
.header .search input[type="text"] {
  background: #191f2c;
  padding: 20px 40px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  color: #FFF;
  width: 100%;
  float: left;
  position: relative;
  z-index: 2;
  outline: none;
}
@media only screen and (max-width: 979px) {
  .header .search input[type="text"] {
    top: 11vh;
    margin: 0 20px;
    width: calc(100% - 40px);
    position: absolute;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.header .search .button-search {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url('../../../i/mkt/blog/icon-search.png') no-repeat center center;
  float: left;
  position: absolute;
  top: 9px;
  right: 27px;
  z-index: 3;
}
@media only screen and (max-width: 979px) {
  .header .search .button-search {
    top: 11vh;
    height: 57px;
  }
}
.header .logo {
  position: absolute;
  top: 50px;
  left: 50px;
}
@media only screen and (max-width: 979px) {
  .header .logo {
    top: 35px;
    left: 20px;
  }
}
.language-selector {
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px 20px 0;
  background: url('../../../i/mkt/blog/icon-arrow-down.png') no-repeat right 14px;
  margin-right: 20px;
  position: relative;
}
@media only screen and (max-width: 979px) {
  .language-selector {
    display: none;
  }
}
.language-selector .selected {
  padding-left: 30px;
  background: url('../../../i/mkt/blog/icon-language.png') no-repeat left 0;
}
.language-selector ul {
  cursor: default;
  display: none;
  position: absolute;
  background-color: #20283a;
  list-style-type: none;
  margin: 0px;
  padding: 20px 50px 20px 30px;
  right: -35px;
  top: 40px;
  z-index: 40;
}
.language-selector ul li {
  margin: 10px 0;
  padding: 10px 0 10px 30px;
  background: url('../../../i/mkt/blog/sprite-flags.png') no-repeat;
}
.language-selector ul li.en {
  background-position: 0 9px;
}
.language-selector ul li.de {
  background-position: 0 -30px;
}
.language-selector ul li.cs {
  background-position: 0 -70px;
}
.language-selector ul li.es {
  background-position: 0 -110px;
}
.language-selector ul li.fr {
  background-position: 0 -150px;
}
.language-selector ul li.it {
  background-position: 0 -190px;
}
.language-selector ul li.pl {
  background-position: 0 -230px;
}
.language-selector ul li.pt {
  background-position: 0 -270px;
}
.language-selector ul li.ru {
  background-position: 0 -310px;
}
.language-selector ul li.jp {
  background-position: 0 -350px;
}
.language-selector ul li a {
  text-decoration: none;
  color: #FFF;
}
.language-selector ul:before {
  display: block;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  background: #20283a;
  content: " ";
  position: absolute;
  top: 0px;
  right: 26px;
}
.language-selector:hover ul {
  display: block;
}
.nav .languages {
  height: 0;
  margin: 20px 0 0;
  overflow: hidden;
  transition: height 300ms ease;
  border-radius: 2px;
  background: #1c2331;
}
.nav .languages.active {
  height: auto;
}
.nav .languages li {
  margin: 0 20px;
  padding: 10px 0 10px 40px;
  background: url('../../../i/mkt/blog/sprite-flags.png') no-repeat;
}
.nav .languages li:first-child {
  margin-top: 20px;
}
.nav .languages li:last-child {
  margin-bottom: 20px;
}
.nav .languages li.en {
  background-position: 0 9px;
}
.nav .languages li.de {
  background-position: 0 -30px;
}
.nav .languages li.cs {
  background-position: 0 -70px;
}
.nav .languages li.es {
  background-position: 0 -110px;
}
.nav .languages li.fr {
  background-position: 0 -150px;
}
.nav .languages li.it {
  background-position: 0 -190px;
}
.nav .languages li.pl {
  background-position: 0 -230px;
}
.nav .languages li.pt {
  background-position: 0 -270px;
}
.nav .languages li.ru {
  background-position: 0 -310px;
}
.nav .languages li.jp {
  background-position: 0 -350px;
}
.nav .languages li a {
  text-decoration: none;
  color: #FFF;
}
.pager-load {
  background-color: #FFF;
  display: block;
  padding: 24px 24px 22px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  color: #31323d;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 3px -1px #ebedf8;
  -moz-box-shadow: 0px 2px 3px -1px #ebedf8;
  box-shadow: 0px 2px 3px -1px #ebedf8;
  margin: 30px 40px 30px 20px;
  user-select: none;
}
@media only screen and (max-width: 979px) {
  .pager-load {
    margin: 0;
  }
}
.pager-count {
  color: #94a0b1;
}
.footer-top {
  background: #273044;
}
.footer-bottom img,
.footer-copy img {
  margin-top: 30px;
}
.footer-bottom p,
.footer-copy p {
  font-size: 13px;
  color: #7c87a2;
  margin: 25px 0 0;
}
.footer-bottom p a,
.footer-copy p a {
  color: #7c87a2;
  text-decoration: underline;
}
.footer [class*="span"] {
  padding-top: 85px;
  padding-bottom: 85px;
}
@media only screen and (max-width: 979px) {
  .footer [class*="span"] {
    padding: 20px;
  }
}
.footer .span6 {
  padding-right: 65px;
  padding-left: 20px;
}
@media only screen and (max-width: 979px) {
  .footer .span6 {
    padding: 20px 40px;
  }
}
.footer .span6 + .span6 {
  padding-right: 20px;
  padding-left: 65px;
}
@media only screen and (max-width: 979px) {
  .footer .span6 + .span6 {
    padding: 20px 40px;
  }
}
.footer .span6 + .span6 h2 {
  border-bottom: 1px solid #31384d;
  margin-bottom: 30px;
  padding-bottom: 26px;
}
@media only screen and (max-width: 979px) {
  .footer .social-icons {
    text-align: center;
    padding: 40px 0 20px;
    margin: 0;
    border-top: 1px solid #31384d;
  }
}
.footer h2 {
  color: #FFF;
  font-size: 28px;
  margin: 35px 0 25px;
}
@media only screen and (max-width: 979px) {
  .footer h2 {
    text-align: center;
  }
}
.footer form {
  margin-bottom: 35px;
}
@media only screen and (max-width: 979px) {
  .footer form {
    margin-bottom: 0;
  }
}
.button,
.hs-button {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  padding: 15px 45px;
  cursor: pointer;
  border: 0;
  text-decoration: none;
  display: inline-block;
}
.button-primary,
.hs-button-primary,
.button.primary,
.hs-button.primary {
  background: #ff7800;
}
.button span,
.hs-button span,
.button.primary,
.hs-button.primary,
.button h2,
.hs-button h2 {
  text-transform: uppercase;
  color: #FFF;
  font-size: 13px;
  line-height: 1;
  font-weight: bold;
}
.button h2,
.hs-button h2 {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.button h2 br,
.hs-button h2 br {
  display: none;
}
:root #content-holder .hs-cta-wrapper .cta_button.cta-text {
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  border-radius: 2px !important;
  padding: 15px 45px !important;
  cursor: pointer !important;
  border: 0 !important;
  text-decoration: none !important;
  display: inline-block !important;
  background: #ff7800 !important;
  text-transform: uppercase !important;
  color: #FFF !important;
  font-size: 13px !important;
  line-height: 1 !important;
  font-weight: bold !important;
}
:root #content-holder .hs-cta-wrapper .cta_button.cta-text span,
:root #content-holder .hs-cta-wrapper .cta_button.cta-text a,
:root #content-holder .hs-cta-wrapper .cta_button.cta-text div,
:root #content-holder .hs-cta-wrapper .cta_button.cta-text h2 {
  text-transform: uppercase !important;
  color: #FFF !important;
  font-size: 13px !important;
  line-height: 1 !important;
  font-weight: bold !important;
}
:root #content-holder .hs-cta-wrapper .cta_button.cta-text h2 {
  margin: 0;
  padding: 0;
  display: inline-block;
}
:root #content-holder .hs-cta-wrapper .cta_button.cta-text h2 br {
  display: none;
}
.form,
.hs-form {
  width: 100%;
}
.form-dark .input-text,
.hs-form-dark .input-text,
.footer .form .input-text,
.footer .hs-form .input-text,
.form-dark .input .hs-input,
.hs-form-dark .input .hs-input,
.footer .form .input .hs-input,
.footer .hs-form .input .hs-input {
  color: #FFF;
  background: #20283a;
}
.form .input-text,
.hs-form .input-text,
.form .input .hs-input,
.hs-form .input .hs-input {
  width: 100%;
  padding: 32px 30px;
  font-size: 13px;
  line-height: 1;
  font-weight: bold;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
@media only screen and (max-width: 680px) {
  .form .input-text,
  .hs-form .input-text,
  .form .input .hs-input,
  .hs-form .input .hs-input {
    padding: 20px 20px;
  }
}
.form .input textarea,
.hs-form .input textarea {
  font-family: 'Proxima Nova', 'Helvetica Neue', 'Roboto', Helvetica, Arial, sans-serif;
}
.form input,
.hs-form input {
  border: 0;
}
.form textarea,
.hs-form textarea {
  display: block;
  height: 200px;
}
.form input.error,
.hs-form input.error,
.form textarea.error,
.hs-form textarea.error {
  border: 1px solid #db4537;
}
.hs-form .hs-error-msgs {
  background-color: #db4537;
  list-style-type: none;
  color: #FFF;
  line-height: 1;
  margin: 0;
  padding: 14px 30px;
  position: relative;
  top: -1px;
}
.hs-form .hs-error-msgs label {
  display: block;
  margin: 0;
}
.new-comments .hs-form-field {
  margin-bottom: 20px;
}
.new-comments label {
  margin-bottom: 15px;
  display: inline-block;
}
.new-comments .hs-input {
  width: 100%;
  padding: 20px 30px;
  font-size: 13px;
  line-height: 1;
  font-weight: bold;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #e6ebf2;
}
.new-comments .hs-form-booleancheckbox .hs-input {
  width: auto;
}
.newsletter .wrapper,
.hs_cos_wrapper_type_blog_subscribe .wrapper,
.newsletter form,
.hs_cos_wrapper_type_blog_subscribe form {
  position: relative;
}
.newsletter .wrapper label,
.hs_cos_wrapper_type_blog_subscribe .wrapper label,
.newsletter form label,
.hs_cos_wrapper_type_blog_subscribe form label {
  display: none;
}
.newsletter .wrapper a,
.hs_cos_wrapper_type_blog_subscribe .wrapper a,
.newsletter form a,
.hs_cos_wrapper_type_blog_subscribe form a,
.newsletter .wrapper .hs_submit,
.hs_cos_wrapper_type_blog_subscribe .wrapper .hs_submit,
.newsletter form .hs_submit,
.hs_cos_wrapper_type_blog_subscribe form .hs_submit {
  position: absolute;
  right: 15px;
  top: 18px;
}
@media only screen and (max-width: 680px) {
  .newsletter .wrapper a,
  .hs_cos_wrapper_type_blog_subscribe .wrapper a,
  .newsletter form a,
  .hs_cos_wrapper_type_blog_subscribe form a,
  .newsletter .wrapper .hs_submit,
  .hs_cos_wrapper_type_blog_subscribe .wrapper .hs_submit,
  .newsletter form .hs_submit,
  .hs_cos_wrapper_type_blog_subscribe form .hs_submit {
    position: initial;
    right: 0;
    top: 0;
    margin-top: 20px;
  }
  .newsletter .wrapper a input,
  .hs_cos_wrapper_type_blog_subscribe .wrapper a input,
  .newsletter form a input,
  .hs_cos_wrapper_type_blog_subscribe form a input,
  .newsletter .wrapper .hs_submit input,
  .hs_cos_wrapper_type_blog_subscribe .wrapper .hs_submit input,
  .newsletter form .hs_submit input,
  .hs_cos_wrapper_type_blog_subscribe form .hs_submit input {
    width: 100%;
    padding: 20px;
  }
}
.social-icons {
  display: table;
  width: 80%;
  margin-left: -4px;
}
@media only screen and (max-width: 979px) {
  .social-icons {
    width: 100%;
  }
}
.social-icons a {
  display: table-cell;
  width: 16.66667%;
  text-align: center;
}
.x-ru .social-icons a {
  width: 12.5%;
}
.layout-detail .discussion h2 {
  font-size: 40px;
  text-align: center;
}
@media only screen and (max-width: 979px) {
  .layout-detail .discussion h2 {
    font-size: 26px;
  }
}
.layout-detail .discussion h2 .comments-hd-count {
  color: #94a0b1;
}
@media only screen and (min-width: 1025px), only screen and (min-width: 980px) and (max-width: 1024px) {
  .layout-detail .discussion {
    width: 780px;
    margin: auto;
  }
}
.layout-detail .intro {
  text-align: center;
  margin-top: 30px;
}
.layout-detail .intro a {
  position: relative;
}
.layout-detail .intro a .tag {
  padding-left: 40px;
}
.layout-detail .intro a .tag:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #FFF;
  position: absolute;
  left: 12px;
  top: 10px;
}
.layout-detail .intro a .tag:before {
  content: " ";
  display: block;
  width: 13px;
  height: 2px;
  position: absolute;
  left: 17px;
  top: 14px;
  background-color: #FFF;
}
.layout-detail .intro h1 {
  color: #FFF;
}
@media only screen and (min-width: 980px) and (max-width: 1024px), only screen and (min-width: 1025px) {
  .layout-detail .intro h1 {
    width: 680px;
    margin-left: auto;
    margin-right: auto;
  }
}
.layout-detail .share {
  position: absolute;
  top: 272px;
  left: -60px;
}
.layout-detail .share a {
  display: block;
}
.layout-detail .share-sticky {
  position: fixed;
  top: 50px;
  left: initial;
  margin-left: -110px;
}
.layout-detail .share-sticky-bottom {
  top: initial;
  bottom: 120px;
}
@media only screen and (max-width: 979px) {
  .layout-detail .share {
    position: static !important;
    top: auto !important;
    left: auto !important;
    margin: 0 auto 20px;
    text-align: center;
  }
  .layout-detail .share a {
    display: inline-block !important;
  }
}
.layout-detail .article .image,
.layout-detail .article .image > div {
  width: 100%;
  height: 470px;
  background-size: cover;
  position: relative;
  z-index: 30;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 979px) {
  .layout-detail .article .image,
  .layout-detail .article .image > div {
    padding-bottom: 56.25%;
    height: 0;
  }
}
.layout-detail .article .image:before {
  content: " ";
  display: block;
  z-index: 5;
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 90%;
  height: 10px;
  margin: 0 5%;
  -webkit-box-shadow: 0px 16px 52px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 16px 52px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 16px 52px 6px rgba(0, 0, 0, 0.2);
}
.layout-detail .article .meta {
  display: block;
  text-align: center;
}
.layout-detail .article .box {
  padding: 50px;
  background: #FFF;
  position: relative;
  z-index: 40;
  margin-top: -100px;
}
.layout-detail .article .box ul,
.layout-detail .article .box ol {
  color: #47566c;
}
@media only screen and (max-width: 979px) {
  .layout-detail .article .box {
    margin-top: 0;
    padding: 30px;
  }
}
@media only screen and (min-width: 980px) and (max-width: 1024px), only screen and (min-width: 1025px) {
  .layout-detail .article .box {
    width: 780px;
    margin-left: auto;
    margin-right: auto;
  }
}
.layout-detail .article .box .meta {
  padding: 0 0 50px;
  margin: -15px 0 25px;
  background: url('../../../i/mkt/blog/article-divider.png') no-repeat 50% 100%;
}
.layout-detail .article .box .meta-author {
  color: #31323d;
}
.layout-detail .article .box .meta-author a {
  text-decoration: none;
  color: #31323d;
}
.layout-detail .article .box .meta-author-image {
  display: inline-block;
}
.layout-detail .article .box .meta-author-image {
  width: 50px;
  height: 50px;
  margin: 0 20px 0 0;
  position: relative;
}
.layout-detail .article .box .meta-author-image img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
@media only screen and (max-width: 979px) {
  .layout-detail .article .box .meta-author-image {
    margin: 10px auto 10px;
    top: auto;
  }
}
@media only screen and (max-width: 979px) {
  .layout-detail .article .box .meta-author,
  .layout-detail .article .box .meta-author-description,
  .layout-detail .article .box .meta-author-image {
    display: block;
  }
  .layout-detail .article .box .meta-added span {
    display: none;
  }
}
.layout-detail .article .box .meta-detailed {
  padding: 80px 0 50px;
  text-align: center;
  border-top: 1px solid #e6ebf2;
}
.layout-detail .article .box .meta-detailed .meta-author,
.layout-detail .article .box .meta-detailed .meta-author-description,
.layout-detail .article .box .meta-detailed .meta-author-image {
  display: block;
}
.layout-detail .article .box .meta-detailed .meta-author-image {
  width: 90px;
  height: 90px;
  margin: 0 auto 30px;
}
.layout-detail .article .box .meta-detailed .meta-author-image img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.layout-detail .article .box .meta-detailed .meta-author {
  font-size: 24px;
  line-height: 1;
  color: #31323d;
  font-weight: bold;
}
.layout-detail .article .box .meta-detailed .meta-author a {
  text-decoration: none;
  color: #31323d;
}
.layout-detail .article .box .meta-detailed .meta-author-description {
  line-height: 1.6667;
  font-size: 18px;
  color: #6c7c92;
}
.layout-detail .article .box .perex,
.layout-detail .article .box p {
  font-size: 24px;
  line-height: 1.5;
  color: #47566c;
  word-wrap: break-word;
}
@media only screen and (max-width: 979px) {
  .layout-detail .article .box .perex,
  .layout-detail .article .box p {
    font-size: 18px;
  }
}
.layout-detail .article .box p {
  font-size: 18px;
  line-height: 1.8887;
  margin: 30px 0;
  word-wrap: break-word;
}
@media only screen and (max-width: 979px) {
  .layout-detail .article .box p {
    font-size: 16px;
  }
}
.layout-detail .article .box blockquote,
.layout-detail .article .box blockquote p {
  font-size: 24px;
  line-height: 1.666;
  font-family: Georgia;
  font-style: italic;
  color: #ff7800;
  text-align: center;
  margin: 50px 40px;
}
@media only screen and (max-width: 979px) {
  .layout-detail .article .box blockquote,
  .layout-detail .article .box blockquote p {
    font-size: 18px;
    margin: 20px 10px;
  }
}
.layout-detail .article .tags {
  margin-top: 60px;
}
.layout-detail .article .tags a,
.layout-detail .article .tags li {
  color: #6c7c92;
  font-size: 17px;
  font-weight: bold;
}
@media only screen and (max-width: 979px) {
  .layout-detail .article .tags a,
  .layout-detail .article .tags li {
    font-size: 16px;
  }
}
.layout-detail .article-related {
  margin: 0 -15px;
  padding-top: 80px;
  border-top: 1px solid #e6ebf2;
}
@media only screen and (max-width: 979px) {
  .layout-detail .article-related {
    padding-top: 0;
    margin: 0;
  }
}
.layout-detail .article-related h2 {
  font-size: 40px;
  text-align: center;
}
@media only screen and (max-width: 979px) {
  .layout-detail .article-related h2 {
    font-size: 26px;
  }
}
.layout-detail .article-related .article-items {
  padding: 0 20px;
}
.layout-detail .article-related .article-item {
  /*@media @mobile {
                    width: ~"calc(33.3333332% - 20px)";
                    margin-bottom: 50px;
                    position: relative;
                    float: left;
                }*/
}
@media only screen and (max-width: 979px) {
  .layout-detail .article-related .article-item-last {
    margin-bottom: 0;
  }
}
.layout-detail .article-related .article-item .box:before {
  background: #FFF;
}
#hs_cos_wrapper_post_body img {
  max-width: 100% !important;
  height: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}
@media only screen and (max-width: 979px), only screen and (min-width: 980px) and (max-width: 1024px) {
  #hs_cos_wrapper_post_body a.cta_button img {
    max-width: 100% !important;
  }
}
#hs_cos_wrapper_post_body h1 {
  color: #31323d !important;
}
#hs_cos_wrapper_post_body h2 {
  font-size: 24px;
  line-height: 1.416667;
  margin: 40px 0 20px;
}
#hs_cos_wrapper_post_body h2 + p,
#hs_cos_wrapper_post_body h3 + p {
  margin-top: 20px;
}
#hs_cos_wrapper_post_body h3 {
  font-size: 20px;
  line-height: 1.31;
  maring: 30px 0 20px;
}
#hs_cos_wrapper_post_body a {
  color: #0087FF;
}
#hs_cos_wrapper_post_body ul,
#hs_cos_wrapper_post_body ol,
#hs_cos_wrapper_post_body dl {
  margin: 0 0 0 17px;
  padding: 0 0 15px;
  font-size: 18px;
  line-height: 1.4;
}
.comment-info {
  margin: 100px 0 70px;
  font-weight: bold;
  text-align: center;
  color: #31323d;
}
.comment-info a {
  text-decoration: none;
}
@media only screen and (max-width: 979px) {
  .comment-info {
    margin: 50px 0;
  }
}
.comment-info-count {
  position: relative;
}
.comment-info-count > span {
  position: relative;
  z-index: 30;
}
.comment-info-count:after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 3px;
  z-index: 20;
  display: inline-block;
  height: 7px;
  width: 100%;
  background-color: #ffecdb;
}
.comment-info-count,
.comment-info-write {
  color: #ff7800;
}
.comment-info-write {
  position: relative;
  text-decoration: none;
}
.comment-info-write:after {
  content: " ";
  position: absolute;
  margin: 0 -3%;
  bottom: -1px;
  left: 0;
  width: 106%;
  height: 2px;
  background-color: #ff7800;
}
.comments-listing .comment {
  padding: 45px 0 35px;
  position: relative;
  border-bottom: 1px solid #e6ebf2;
}
.comments-listing .comment-from {
  font-size: 18px;
  line-height: 1;
  color: #31323d;
  border-right: 1px solid #e6ebf2;
  padding-right: 17px;
  margin-right: 20px;
  padding-left: 40px;
  background: url('../../../i/mkt/blog/comment.png') no-repeat 0 0;
}
.comments-listing .comment-from a,
.comments-listing .comment-from h4 {
  font-size: 18px;
  line-height: 1;
  color: #31323d;
  text-decoration: none;
  margin: 0;
  padding: 0;
}
.comments-listing .comment.comment-active .comment-from {
  background: url('../../../i/mkt/blog/comment-active.png') no-repeat 0 0;
  font-weight: bold;
}
.comments-listing .comment-date {
  font-size: 13px;
  font-weight: bold;
  color: #94a0b1;
  padding: 2px 0 0;
}
.comments-listing .comment-from,
.comments-listing .comment-date {
  float: left;
}
.comments-listing .comment-body {
  font-size: 18px;
  line-height: 1.8889;
  color: #47566c;
  margin: 30px 40px 0 0;
}
.comments-listing .comment-body:before {
  content: " ";
  clear: both;
  display: block;
  width: 100%;
  overflow: hidden;
}
.comments-listing .comment-reply-to {
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  color: #94a0b1;
  position: absolute;
  top: 0;
  right: 0;
}
.comments-listing .comment.depth-1 {
  padding-left: 75px;
}
.comments-listing .comment.depth-2 {
  padding-left: 150px;
}
.comments-listing .comment.depth-3 {
  padding-left: 225px;
}
.comments-listing .comment.depth-4 {
  padding-left: 300px;
}
.new-comments {
  margin-top: 80px;
}
.new-comments.no-comments {
  display: none;
}
@media only screen and (max-width: 979px) {
  .new-comments {
    margin-top: 40px;
  }
}
.new-comments label {
  display: none;
}
.new-comments .hs_firstname,
.new-comments .hs_email {
  width: calc(50% - 10px);
  float: left;
}
.new-comments .hs_firstname {
  margin-right: 20px;
}
.comment.depth-0 {
  border-top: 0 !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.x-authors #content-holder h1 {
  text-align: center;
  color: #FFF;
  font-size: 44px;
}
.x-authors #content-holder .layout-authors {
  background: #FFF;
  min-height: 1200px;
  position: relative;
  z-index: 20;
  padding: 80px 120px;
}
@media only screen and (max-width: 979px) {
  .x-authors #content-holder .layout-authors {
    padding: 50px;
  }
}
.x-authors #content-holder .layout-authors .authors-items {
  margin-bottom: 40px;
}
@media only screen and (min-width: 1025px), only screen and (min-width: 980px) and (max-width: 1024px) {
  .x-authors #content-holder .layout-authors .authors-items {
    display: flex;
  }
}
.x-authors #content-holder .layout-authors .authors-items a {
  text-decoration: none;
  display: block;
  background: url('../../../i/mkt/blog/author-arrow.png') no-repeat center bottom 20px;
  padding-bottom: 42px;
}
.x-authors #content-holder .layout-authors .authors-item {
  border-bottom: 1px solid #e6ebf2;
}
@media only screen and (min-width: 1025px), only screen and (min-width: 980px) and (max-width: 1024px) {
  .x-authors #content-holder .layout-authors .authors-item {
    width: 290px;
    margin: 0 25px;
  }
}
@media only screen and (max-width: 979px) {
  .x-authors #content-holder .layout-authors .authors-item {
    max-width: 250px;
    margin: 0 auto 25px;
  }
}
.x-authors #content-holder .layout-authors .image {
  background: #e6ebf2;
  box-shadow: 0px 15px 29px -6px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 5;
}
@media only screen and (min-width: 1025px), only screen and (min-width: 980px) and (max-width: 1024px) {
  .x-authors #content-holder .layout-authors .image {
    width: 250px;
    height: 250px;
  }
}
.x-authors #content-holder .layout-authors .image img {
  width: 100%;
}
.x-authors #content-holder .layout-authors .box {
  margin: -32px 10px 0;
  padding: 12px 22px 12px;
  background: #FFF;
  position: relative;
  z-index: 10;
  text-align: center;
  min-height: 50px;
}
.x-authors #content-holder .layout-authors .box h3 {
  font-size: 20px;
  margin: 10px 0;
}
.x-author-detail .layout-author-detail {
  background: #FFF;
  margin-top: 140px;
  padding: 80px 120px 0;
  position: relative;
}
@media only screen and (max-width: 979px) {
  .x-author-detail .layout-author-detail {
    padding: 50px 50px 0;
  }
}
.x-author-detail .layout-author-detail .author-image {
  position: absolute;
  top: -120px;
  width: calc(100% - 240px);
  text-align: center;
}
.x-author-detail .layout-author-detail .author-image img {
  box-shadow: 0px 15px 29px -6px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 979px) {
  .x-author-detail .layout-author-detail .author-image {
    top: -80px;
    width: calc(100% - 100px);
  }
  .x-author-detail .layout-author-detail .author-image img {
    width: 250px;
  }
}
@media only screen and (max-width: 486px) {
  .x-author-detail .layout-author-detail .author-image img {
    width: 180px;
  }
}
.x-author-detail .layout-author-detail h1,
.x-author-detail .layout-author-detail h2 {
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 15px;
  text-align: center;
  color: #31323d;
  margin-top: 120px;
}
.x-author-detail .layout-author-detail .h2sub {
  font-size: 18px;
  font-weight: bold;
  color: #6c7c92;
  margin: 0 0 40px;
  text-align: center;
}
.x-author-detail .layout-author-detail .author-bio {
  line-height: 32px;
  text-align: center;
  padding: 0;
}
.x-author-detail .layout-author-detail ul.author-social {
  list-style-type: none;
  margin-top: 40px;
  padding: 0 0 40px;
  border-bottom: 1px solid #e6ebf2;
  text-align: center;
}
.x-author-detail .layout-author-detail ul.author-social li {
  display: inline-block;
  margin: 0px 6px;
}
.x-author-detail .layout-author-detail-related {
  background: none;
  margin: 0;
  padding: 0 170px 50px;
}
@media only screen and (max-width: 979px) {
  .x-author-detail .layout-author-detail-related {
    padding: 0 50px 50px;
  }
}
.x-author-detail .layout-author-detail-related h2 {
  margin: 60px 0;
}
.x-author-detail .layout-author-detail-related .article-items {
  /*justify-content: center;*/
}
.x-author-detail .layout-author-detail-related .article-items div:last-child {
  margin-right: 0;
}
@media only screen and (min-width: 1025px) {
  .x-author-detail .layout-author-detail-related .article-item .inner {
    height: calc(100% - 129px);
  }
}
.x-author-detail .layout-author-detail-related .article-item .box {
  background: #FFF;
}
@media only screen and (min-width: 1025px) {
  .x-author-detail .layout-author-detail-related .article-item .box {
    height: 100%;
  }
}
.x-author-detail .layout-author-detail-related .article-item .box:before {
  background: #FFF;
}
.x-author-detail .layout-author-detail-related .button-block {
  text-align: center;
  border-top: 1px solid #e6ebf2;
  padding: 50px;
  margin-top: 50px;
}
.x-author-detail .pager {
  background: #fafcfd;
}
.slick-arrow {
  display: none !important;
}
.article-item-ad {
  background-color: #2e354f;
}
.article-item-ad a {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}
.article-item-ad a > div {
  display: inline-block;
  text-align: center;
}
.article-item-ad a p {
  font-weight: bold;
  color: #FFF;
}
@media only screen and (max-width: 979px) {
  .article-item-ad {
    text-align: center;
    padding: 30px !important;
  }
  .article-item-ad a {
    display: block;
  }
}
/* Browser specific fixes */
.br-internetexplorer .article-item-large h1 {
  color: #000;
}
.br-internetexplorer.br-ver-9 .article-item-ad {
  min-height: 480px;
  text-align: center;
}
.br-internetexplorer.br-ver-9 .article-item-ad img {
  padding-top: 30px;
}
@media only screen and (max-width: 979px) {
  .br-internetexplorer.br-ver-9 .article-item-ad {
    min-height: auto;
  }
}
.br-internetexplorer.br-ver-9 .gsc-control-cse {
  background: none !important;
  border: 0 !important;
}
#subscribe-to-newsletter p{
  padding-bottom: 5%;
  text-align: justify;
}
